import LinearProgress from '@material-ui/core/LinearProgress';

import React, { useEffect, useContext } from 'react';

import { ClipLoader } from 'react-spinners';

import { DataTable } from '../DataTable';
import OrderModulo from '../OrderModulo';
import OrderTelas from '../OrderTelas/index';
import Paginated from './components/Paginated';
import { Container, ContainerLoader, ContainerTable } from './styles';

import { useSearch } from './SearchContext';
import { Impressao } from './components/Impressao';
import { SectionSearch } from './Screen/SectionSearch';
import { CardContainer } from './components/CardContainer';
import { useHotkeys } from 'react-hotkeys-hook';
import { HotKeys, configure } from 'react-hotkeys';
import { useQueryClient } from 'react-query';

export const SearchContent: React.FC = () => {
  const {
    colors,
    showTable,
    setOpenOrderModal,
    setOpenOrderTelasModal,
    handleSortField,
    responseData,
    columns,
    openOrderModal,
    totalsAgregadores,
    onRowClick,
    openOrderTelasModal,
    sortCampos,
    page,
    updateFiltroContextoWhenOnRowClick,
    limit,
    loading,
    loadingTable,
    newData,
    removeAllFilters,
    setShowImpressao,
    showImpressao,
    RefetchView,
    returnSearch,
    onlyReport,
    orderModulo,
    orderTelas,
    setIdFiltro,
    setIsOpenModal,
    setValue,
    getValues,
    isOpenModal,
    setOpenPopupView,
    codTela,
    getOrderBy,
    backSearch,
  } = useSearch();

  const queryClient = useQueryClient();

  configure({
    ignoreTags: ['input', 'select', 'textarea'],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ignoreEventsCondition(keyEvent: KeyboardEvent) {
      return false;
    },
  });

  useEffect(() => {
    setValue('shouldOpenImpressao', showTable);
  }, [showTable]);
  useEffect(() => {
    setValue('showImpressao', showImpressao);
  }, [showImpressao]);
  useEffect(() => {
    setValue('loadingTable', loadingTable);
  }, [loadingTable]);

  const validaTelaAtalho = () => {
    const cod_tela_active: any = queryClient.getQueryData(`cod_tela_active`);
    if (cod_tela_active) {
      if (cod_tela_active.cod_tela === codTela) {
        return true;
      }
    }

    return false;
  };

  // Atalho para novo cadastro
  useHotkeys(
    'ctrl+alt+n',
    () => {
      const validaAtalho = validaTelaAtalho();
      if (validaAtalho) {
        if (!onlyReport) {
          const showTableVal = getValues('shouldOpenImpressao');
          const orderBy = getOrderBy(columns);
          queryClient.setQueryData(`cacheLastSearch_${codTela}`, {
            pag: page,
            limite: limit,
            orderBy,
            shouldReturnSearch: showTableVal,
          });
          newData();
        }
      }
    },
    [],
  );

  // Atalho para pesquisa
  useHotkeys(
    'ctrl+alt+p',
    () => {
      const validaAtalho = validaTelaAtalho();
      if (validaAtalho) {
        RefetchView();
        setValue('shouldOpenImpressao', true);
      }
    },
    [],
  );

  // Atalho para limpar a tela
  useHotkeys(
    'ctrl+alt+l',
    () => {
      const validaAtalho = validaTelaAtalho();
      if (validaAtalho) {
        const shouldClearFilter = getValues('shouldClearFilter');
        if (shouldClearFilter) {
          removeAllFilters();
          setValue('shouldClearFilter', false);
        }
      }
    },
    [],
  );
  useHotkeys(
    'ctrl+alt+i',
    () => {
      const validaAtalho = validaTelaAtalho();
      if (validaAtalho) {
        const shouldOpenImpressao = getValues('shouldOpenImpressao');
        if (shouldOpenImpressao) {
          setShowImpressao(true);
        }
      }
    },
    [],
  );
  useHotkeys(
    'ctrl+alt+r',
    () => {
      const validaAtalho = validaTelaAtalho();
      if (validaAtalho) {
        const shouldShowImpressao = getValues('showImpressao');
        const loadingTableValue = getValues('loadingTable');
        if (!isOpenModal && !shouldShowImpressao && !loadingTableValue) {
          returnSearch();
          setValue('shouldOpenImpressao', false);
        }
      }
    },
    [],
  );
  useHotkeys(
    'ctrl+alt+o',
    () => {
      if (orderModulo) {
        setOpenOrderModal(true);
      }
    },
    [],
  );
  useHotkeys(
    'ctrl+alt+t',
    () => {
      if (orderTelas) {
        setOpenOrderTelasModal(true);
      }
    },
    [],
  );
  useHotkeys(
    'ctrl+alt+f',
    () => {
      const validaAtalho = validaTelaAtalho();
      if (validaAtalho) {
        const shouldClearFilter = getValues('shouldClearFilter');
        if (shouldClearFilter) {
          setIdFiltro('');
          setIsOpenModal(true);
        }
      }
    },
    [],
  );
  useHotkeys(
    'ctrl+alt+v',
    () => {
      const validaAtalho = validaTelaAtalho();
      if (validaAtalho) {
        setOpenPopupView(true);
        setTimeout(() => {
          setOpenPopupView(false);
        }, 100);
      }
    },
    [],
  );

  const LinearProgressBar: React.FC = () => {
    return (
      <LinearProgress style={{ background: '#c2a0f8', color: '#8850BF' }} />
    );
  };
  const Loading: React.FC = () => {
    return (
      <ContainerLoader>
        <ClipLoader color={colors.primary} />
      </ContainerLoader>
    );
  };

  const keyMap = {
    hkNewData: 'ctrl+alt+n',
    hkSearch: 'ctrl+alt+p',
    hkClearFilters: 'ctrl+alt+l',
    hkShowImpressao: 'ctrl+alt+i',
    hkOrdernarModulo: 'ctrl+alt+o',
    hkOrdernarTelas: 'ctrl+alt+t',
    hkOpenModalCard: 'ctrl+alt+f',
    hkReturnSearch: 'ctrl+alt+r',
    hkReturnOpenView: 'ctrl+alt+v',
  };

  const keyHandlers = {
    hkShowImpressao: () => {
      const validaAtalho = validaTelaAtalho();
      if (validaAtalho) {
        const shouldOpenImpressao = getValues('shouldOpenImpressao');
        if (shouldOpenImpressao) {
          setShowImpressao(true);
        }
      }

      return false;
    },
    hkNewData: () => {
      const validaAtalho = validaTelaAtalho();
      if (validaAtalho) {
        if (!onlyReport) {
          const orderBy = getOrderBy(columns);

          queryClient.setQueryData(`cacheLastSearch_${codTela}`, {
            pag: page,
            limite: limit,
            orderBy,
            shouldReturnSearch: showTable,
          });
          newData();
        }
      }

      return false;
    },
    hkSearch: () => {
      const validaAtalho = validaTelaAtalho();
      if (validaAtalho) {
        RefetchView();
        setValue('shouldOpenImpressao', true);
      }

      return false;
    },
    hkClearFilters: () => {
      const validaAtalho = validaTelaAtalho();
      if (validaAtalho) {
        const shouldClearFilter = getValues('shouldClearFilter');
        if (shouldClearFilter) {
          removeAllFilters();
          setValue('shouldClearFilter', false);
        }
      }

      return false;
    },
    hkReturnSearch: () => {
      const validaAtalho = validaTelaAtalho();
      if (validaAtalho) {
        const shouldShowImpressao = getValues('showImpressao');
        const loadingTableValue = getValues('loadingTable');
        if (!isOpenModal && !shouldShowImpressao && !loadingTableValue) {
          returnSearch();
          setValue('shouldOpenImpressao', false);
        }
      }
      return false;
    },
    hkOrdernarModulo: () => {
      if (orderModulo) {
        setOpenOrderModal(true);
      }
      return false;
    },
    hkOrdernarTelas: () => {
      const validaAtalho = validaTelaAtalho();
      if (validaAtalho) {
        if (orderTelas) {
          setOpenPopupView(false);
          setOpenOrderTelasModal(true);
        }
      }
      return false;
    },
    hkOpenModalCard: () => {
      const shouldClearFilter = getValues('shouldClearFilter');
      const validaAtalho = validaTelaAtalho();
      if (validaAtalho) {
        if (shouldClearFilter) {
          setIdFiltro('');
          setIsOpenModal(true);
        }
      }
      return false;
    },
    hkReturnOpenView: () => {
      const validaAtalho = validaTelaAtalho();
      if (validaAtalho) {
        setOpenPopupView(true);
        setTimeout(() => {
          setOpenPopupView(false);
        }, 100);
      }

      return false;
    },
  };

  return (
    <HotKeys id="hotkeys" keyMap={keyMap} handlers={keyHandlers} tabIndex={-1}>
      <Container
        style={{
          display: loading ? '' : 'none',
        }}
      >
        <Loading />
      </Container>
      <Container
        style={{
          minHeight: '40rem',
          display: loading ? 'none' : '',
        }}
      >
        <Impressao />
        <SectionSearch />
        <div>
          <CardContainer />
          {showTable && !backSearch && (
            <ContainerTable
              style={{
                height: 'auto',
                width: '100%',
                transform: 'translationY(.5)',
              }}
              animate={{
                x: 0,
                y: 5,
                scale: 1,
                rotate: 0,
              }}
              transition={{ duration: 0.5 }}
            >
              <DataTable
                columns={columns}
                rows={responseData && !loadingTable ? responseData.data : []}
                loading={loadingTable}
                components={{
                  LoadingOverlay: LinearProgressBar,
                  Pagination: Paginated,
                }}
                totals={totalsAgregadores}
                onRowClick={(rowClickValue) => {
                  updateFiltroContextoWhenOnRowClick(page, limit, columns);
                  onRowClick(rowClickValue);
                }}
                handleSort={handleSortField}
                sortCampos={sortCampos}
              />
            </ContainerTable>
          )}
        </div>

        {openOrderModal && (
          <OrderModulo setOpenOrderModal={setOpenOrderModal} />
        )}

        {openOrderTelasModal && (
          <OrderTelas
            isShow={openOrderTelasModal}
            setIsShow={setOpenOrderTelasModal}
          />
        )}
      </Container>
    </HotKeys>
  );
};
